<template>
  <li class="mentor-block">
    <div class="gradient-border"/>
    <div class="flex pb-5">
      <figure class="flex-shrink-0 text-sm text-secondary-lighter">
        <img :src="mentor.avatarUrl ? mentor.avatarUrl : ''" class="mentor-block--img object-cover">
        <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="mt-3 ml-3 mr-1"/>
        <span class="mt-3">{{ mentor.stad }}</span>
      </figure>
      <main class="mt-2 px-4">
        <header class="h3">
          {{ mentor.name }}
        </header>
        <p class="mb-0">
          {{ mentor.introductie }}
        </p>
        <nuxt-link v-if="mentor.profiel" :to="{name: 'ouders-begeleiders-slug', params: {slug: mentor.profiel.slug}}" class="text-xs text-secondary underline">
          Lees meer
        </nuxt-link>
      </main>
    </div>
    <footer class="px-3 pt-3 flex bg-primary-light">
      <div class="flex flex-wrap">
        <div v-for="kwalificatie in mentor.kwalificaties" :key="kwalificatie.title" class="mentor-block--tag">
          {{ kwalificatie.title }}
        </div>
        <div v-for="doelgroep in mentor.doelgroepen" :key="doelgroep.title" class="mentor-block--tag">
          {{ doelgroep.title }}
        </div>
      </div>
    </footer>
  </li>
</template>

<script>
export default {
  props: {
    mentor: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.mentor-block {
  @apply w-full bg-white list-none;
  box-shadow: 1px 1px 5px 1px rgba(222, 220, 220, 0.5);
  &--img {
    height: 150px;
    width: 150px;
  }
  &--tag {
    @apply flex items-center justify-center bg-white px-3 py-1 mr-2 mb-3 text-sm;
    border-radius: 11.5px;
  }
}
</style>
