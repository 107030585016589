<template>
  <div class="flex flex-wrap relative">
    <div class="hidden lg:block absolute right-0 mt-20">
      <img src="~/assets/img/shapes/circle-drk-red.svg">
    </div>
    <img :src="imageUrl" class="header-img object-cover w-full lg:w-3/5">

    <section class="w-full px-3 lg:px-0 lg:w-2/5 xl:w-1/4">
      <div class="dialog-block w-full">
        <h1 v-if="headerTxt" class="h2 text-secondary mb-4">{{ headerTxt }}</h1>
        <slot/>
        <nuxt-link v-if="btn" :to="btnUrl" class="btn btn-secondary inline-block mt-3">
          {{ btnTxt }}
        </nuxt-link>
        <div class="speech-corner absolute">
          <img src="~/assets/img/speechcorner.svg">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ImgDialogHeader',
  props: {
    headerTxt: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: 'https://place-hold.it/1200.jpg/666/fff/000',
    },
    btn: {
      type: Boolean,
      default: false,
    },
    btnTxt: {
      type: String,
      default: 'Button tekst',
    },
    btnUrl: {
      type: String,
      default: '/',
    },
  },

};
</script>

<style lang="scss" scoped>
.header-img {
  height: 40vh;
  @screen lg {
    height: 650px;
  }
}

.dialog-block {
  @apply z-20 bg-white shadow-base relative rounded-md p-6;
  margin-top: -50px;
  @screen lg {
    @apply px-10 py-12;
    margin: 100px 0 0 -150px;
  }
  .speech-corner {
    @apply w-12;
    top: 0;
    left: 25%;
    transform: translateY(-100%)translateX(-100%)rotate(90deg);
    @screen lg {
      left: 0;
      top: 50%;
      transform: translateY(-50%)translateX(-100%)rotate(0deg);
    }
  }
}
</style>
