<template>
  <div class="container relative flex w-full justify-center pb-24">
    <div class="circle">
      <img src="~/assets/img/shapes/circle-light-red.svg" class="circle--shape">
      <div v-if="circleTxt" class="circle--txt">{{ circleContent }}</div>
    </div>

    <section class="flex flex-wrap lg:flex-no-wrap justify-between items-start w-full lg:w-3/4">
      <figure class="z-10 flex justify-center lg:justify-start w-full lg:w-auto mt-8">
        <img :src="imageUrl" class="clip-circle w-100 h-100 object-cover">
      </figure>

      <main class="w-full text-center lg:w-auto lg:text-left lg:pt-8 lg:pl-4 relative">
        <div v-if="headerTxt" class="h3 mb-3">{{ headerTxt }}</div>
        <slot/>
        <nuxt-link v-if="btnUrl" :to="btnUrl" class="btn btn-secondary inline-block mt-3">
          {{ btnText }}
        </nuxt-link>
      </main>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    headerTxt: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'Button tekst',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    btnUrl: {
      type: String,
      default: '',
    },
    circleTxt: {
      type: Boolean,
      default: false,
    },
    circleContent: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>

.circle {
  @apply w-full absolute z-0;
  top: 5%;
  left: 5%;
  @screen md {
    top: -7em;
    transform: scale(.8);
  }
  @screen lg {
    top: -15em;
    transform: scale(1);
  }
  &--shape {
    @apply z-50 top-0;
    left: -25%;
    width: 230px;
    @screen md {
      width: 440px;
    }
  }
  &--txt {
    @apply absolute bg-white hidden text-xl font-bold;
    z-index: -1;
    width: 400px;
    height: 400px;
    top: 20px;
    left: 20px;
    padding: 0 20px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    transform: translateY(-3%);
    @screen md {
      @apply flex;
    }
  }
}

.clip-circle {
  clip-path: circle(150px at center);
  min-width: 400px;
  @screen lg {
    clip-path: circle(180px at center);
  }
}
</style>
