<template>
  <div :class="clickableLinks ? 'w-100 mr-4' : 'nolink-style'" class="relative bg-white shadow-base">
    <header class="block-header">
      <img src="~/assets/img/shapes/redshape-header.svg" class="w-100">
      <span class="block-header--title text-xl text-white font-bold">{{ pakket.title || headerTitle }}</span>
    </header>

    <main class="py-10 flex justify-center">
      <div :class="clickableLinks ? 'w-2/5' : 'nolink-style--content'" class="w-2/5 flex flex-col">
        <template
          v-if="clickableLinks"
        >
          <div v-for="link in links" :key="`${link.url}-${link.text}`">
            <div v-if="link.url === '#'" class="h3">
              {{ link.text }}
            </div>
            <nuxt-link v-else :to="link.url" class="btn-primary flex justify-center w-full h-full">
              {{ link.text }}
            </nuxt-link>
            <ul class="mt-3">
              <li v-for="option in link.children" :key="option.url" class="btn-primary mb-3">
                <nuxt-link :to="option.url" class="flex justify-center py-1 w-full h-full">
                  {{ option.text }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </template>

        <template v-else>
          <ul class="pb-4">
            <li v-for="item in pakket.voordelen" :key="item.title" class="flex items-center text-sm mb-3">
              <font-awesome-icon :icon="['fas', 'check']" class="text-secondary-lighter mr-2"/>
              {{ item.title }}
            </li>
          </ul>
          <div class="absolute left-0 right-0 bottom-0 flex justify-center pb-4 text-sm">
            {{ pakket.duur }}
          </div>
        </template>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    clickableLinks: {
      type: Boolean,
      default: true,
    },
    pakket: {
      type: Object,
      default: () => ({}),
    },
    links: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" scoped>
.block-header {
  @apply relative;
  &--title {
    @apply absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.nolink-style {
  @apply w-100 mb-6;
  @screen lg {
    @apply mr-16 mb-0;
    width: 300px;
  }
  &:nth-of-type(3) {
    @apply mr-0;
  }

  &--content {
    @apply w-full px-12;
    min-height: 300px;
    @screen lg {
      min-height: 400px;
    }
  }
}
</style>
