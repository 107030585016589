<template>
  <div class="-mt-24 mb-12 relative overflow-x-hidden">
    <section class="wave-shape pt-24">
      <div class="flex w-5/6 lg:w-3/5 container relative pt-12">
        <div class="flex flex-col pb-64 lg:pb-0">
          <div class="mb-3">Het geheim van:</div>
          <img src="~/assets/img/logo/logotekst-rood.svg" alt="Mijn Superkracht.nl" class="w-100">

          <ul class="mt-6 lg:mt-10 pb-12 md:pb-24 lg:pb-0">
            <li v-for="item in items" :key="item.title" class="text-lg mb-4">
              <font-awesome-icon :icon="['far', 'fist-raised']" class="text-secondary mr-4"/>
              <span class="text-sm lg:text-base font-bold">{{ item.title }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="flex flex-wrap justify-center container relative z-10 -mt-24 mb-6">
      <div class="illustrations z-0">
        <img src="~/assets/img/characters/character-side.svg" class="illustrations--character">
        <img src="~/assets/img/characters/backgroundshadow.svg" class="illustrations--shadow">
      </div>
      <quicklinks-block
        v-for="(group, index) in quickMenu"
        :key="group.text || index"
        :header-title="group.text || ''"
        :links="group.children"
        class="z-20 mb-8 lg:mb-0"
      />
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import QuicklinksBlock from '~/components/QuicklinksBlock';

export default {
  components: {QuicklinksBlock},
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    ...mapState({
      quickMenu: state => state.quickMenu,
    }),
  },
};
</script>

<style lang="scss" scoped>
.wave-shape {
  @apply w-full;
  background-image: url("~assets/img/shapes/lightwave.svg");
  background-repeat: no-repeat;
  min-height: 600px;
}

.illustrations {
  @apply relative w-full;
  @screen md {
    @apply absolute;
  }
  &--character {
    @apply absolute;
    right: 10%;
    bottom: 0;
    transform: translateY(25%)scale(.6);
    @screen sm {
      right: 30%;
    }
    @screen lg {
      right: 15%;
      transform: translateY(10%)scale(1);
    }
    @screen xl {
      right: 30%;
    }
  }
  &--shadow {
    @apply absolute;
    z-index: -1;
    right: -10%;
    bottom: 0;
    transform: translateY(10%);
    @screen sm {
      right: 10%;
    }
    @screen lg {
      right: 0;
    }
    @screen xl {
      right: 15%;
    }
  }
}
</style>
