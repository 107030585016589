<template>
  <div>
    <home-header
      :btn="!!page.template.buttonlink"
      :btn-txt="page.template.buttontekst"
      :btn-url="page.template.buttonlink"
      :header-txt="page.template.kop"
      :image-url="page.active_image ? page.active_image.url : ''"
    >
      <span class="prose content" v-html="page.template.tekst"/>
    </home-header>

    <wave-quicklinks :items="page.template.krachten"/>

    <circle-img-content
      v-if="$apollo.loading"
      :header-txt="page.template.kop_2"
      :btn-text="page.template.buttontekst_2"
      :btn-url="page.template.buttonlink_2"
      :image-url="extraImage"
    >
      <span class="prose content" v-html="page.template.tekst_2"/>
    </circle-img-content>

    <mentor-quickview
      header-txt="Een greep uit onze begeleiders"
    />
  </div>
</template>

<script>
import page from '~/plugins/mixins/page';
import HomeHeader from '~/components/headers/ImgDialogHeader';
import WaveQuicklinks from '~/components/fullwidth-blocks/WaveQuicklinks';
import CircleImgContent from '~/components/fullwidth-blocks/CircleImgContent';
import MentorQuickview from '~/components/begeleiders/MentorQuickview';

export default {
  components: {CircleImgContent, MentorQuickview, WaveQuicklinks, HomeHeader},
  mixins: [page],
  computed: {
    extraImage() {
      return this.page.template?.afbeelding_2[0]?.url;
    },
  },
};
</script>
