<template>
  <div class="container w-full sm:w-3/4 lg:w-2/3 xl:w-full">
    <header v-if="headerTxt" class="h3 text-center mb-12">
      {{ headerTxt }}
    </header>

    <ul v-if="!$apollo.loading" class="grid grid-cols-1 xl:grid-cols-3 gap-4">
      <mentor-item v-for="begeleider in begeleiders.data" :key="begeleider.name" :mentor="begeleider"/>
    </ul>

    <div class="flex justify-center pt-10">
      <nuxt-link to="/ouders/begeleiders/zoeken" class="inline-block btn btn-secondary">
        Zoek een begeleider
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import MentorItem from '~/components/begeleiders/MentorItem';
import begeleiders from '~/graphql/queries/begeleiders';

export default {
  components: {MentorItem},
  props: {
    headerTxt: {
      type: String,
      default: '',
    },
  },
  apollo: {
    begeleiders: {
      query: begeleiders,
      variables: {
        limit: 3,
        page: 1,
        random: true,
      },
    },
  },
};
</script>
