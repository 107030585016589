// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/shapes/lightwave.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wave-shape[data-v-4a822a01]{width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;min-height:600px}.illustrations[data-v-4a822a01]{position:relative;width:100%}@media (min-width:768px){.illustrations[data-v-4a822a01]{position:absolute}}.illustrations--character[data-v-4a822a01]{position:absolute;right:10%;bottom:0;transform:translateY(25%) scale(.6)}@media (min-width:640px){.illustrations--character[data-v-4a822a01]{right:30%}}@media (min-width:1024px){.illustrations--character[data-v-4a822a01]{right:15%;transform:translateY(10%) scale(1)}}@media (min-width:1280px){.illustrations--character[data-v-4a822a01]{right:30%}}.illustrations--shadow[data-v-4a822a01]{position:absolute;z-index:-1;right:-10%;bottom:0;transform:translateY(10%)}@media (min-width:640px){.illustrations--shadow[data-v-4a822a01]{right:10%}}@media (min-width:1024px){.illustrations--shadow[data-v-4a822a01]{right:0}}@media (min-width:1280px){.illustrations--shadow[data-v-4a822a01]{right:15%}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
